
import Vue from 'vue';
import {Component} from "vue-property-decorator";
import ContainerTypeRepository from "@/api/repositories/ContainerTypeRepository";
import ContainerType from "@/models/ContainerType.model";
import {ROUTE_CONTAINER_TYPE_REQUESTS, ROUTE_CONTAINER_TYPE_REQUESTS_WITH_ID} from "@/router/routes";

@Component({
  components: {
    ContainerTypeRequestsList: () => import(
      /* webpackChunkName: "ContainerTypeRequestsList" */
      '@/components/ContainerType/ContainerTypeRequestList.component.vue'
    )
  }
})
export default class ContainerTypeRequestsView extends Vue {
  /**
   * search field text
   * @private
   */
  private search: string = '';

  /**
   * flag that indicates if the screen is loading
   * @private
   */
  private isLoading = false;

  /**
   * list of pending container types
   * @private
   */
  private pendingContainerTypes: ContainerType[] = [];

  /**
   * list of handled container types
   * @private
   */
  private handledContainerTypes: ContainerType[] = [];

  /**
   * flag that indicates which selection of items is shown
   * @private
   */
  private showProvedItems = false;

  /**
   * fetches the containerTypes that are either already edited or still pending in request
   * @private
   */
  private async created(): Promise<void> {
    this.isLoading = true;
    await this.loadTables();
    this.$nextTick(() => this.isLoading = false);
  }

  /**
   * is called when an container type is accepted, changes status of the container and reloads the table
   * @param item
   * @private
   */
  private async onItemAccepted(item: ContainerType): Promise<void> {
    this.isLoading = true;
    try {
      // sets the status of the container type to accepted
      await ContainerTypeRepository.changeContainerTypeStatus(item.id!, true);
      await this.loadTables();
    } finally {
      this.isLoading = false;
    }
  }

  /**
   * is called when an container type is denied, changes status of the container and reloads the table
   * @param item
   * @private
   */
  private async onItemDeclined(item: ContainerType): Promise<void> {
    this.isLoading = true;
    try {
      // sets the status of the container type to declined
      await ContainerTypeRepository.changeContainerTypeStatus(item.id!, false);
      await this.loadTables();
    } finally {
      this.isLoading = false;
    }
  }

  /**
   * reloads the container type arrays
   * @private
   */
  private async loadTables(): Promise<void> {
    try {
      // gets the container types of all companies
      const response = await ContainerTypeRepository.getRequestedContainerTypes();
      this.handledContainerTypes = response.data.handledContainerTypes;
      this.pendingContainerTypes = response.data.requestedContainerTypes;
    } catch(_) {
      this.handledContainerTypes = [];
      this.pendingContainerTypes = [];
    }
  }

  /**
   * changes the selection to the one that is passed
   * @param value
   * @private
   */
  private onSelectionChange(value: boolean): void {
    this.showProvedItems = value;
  }

  /**
   * is called when a route change is triggered by the request list, checks if a id is passed and either links to the
   * general request view or the specific one with a id
   * @param id
   * @private
   */
  private onRouteChangeRequested(id?: string) {
    if(!id) {
      this.$router.push({name: ROUTE_CONTAINER_TYPE_REQUESTS});
      return;
    }

    this.$router.push({name: ROUTE_CONTAINER_TYPE_REQUESTS_WITH_ID, params: {containerTypeId: id!}});
  }
}
